<template>
  <div id="the-translation">
    <van-dropdown-menu>
      <van-dropdown-item v-model="from" :options="fromList" />
      <van-dropdown-item v-model="to" :options="toList" />
    </van-dropdown-menu>
    <van-field
      v-model="textual"
      autosize
      type="textarea"
      maxlength="2000"
      placeholder="请输入要翻译的内容......"
      show-word-limit
    />
    <div class="translated" v-show="translated == '' ? false : true">
      <p>翻译结果如下</p>
      <van-field
        v-model="translated"
        autosize
        type="textarea"
        placeholder="译文"
        show-word-limit
      />
    </div>
    <van-button @click="translate" class="translate-btn" type="primary" block
      >翻译</van-button
    >
  </div>
</template>

<script>
import Axios from "axios";
export default {
  data() {
    return {
      from: "auto",
      to: "en",
      fromList: [
        { text: "自动检测", value: "auto" },
        { text: "中文", value: "zh" },
        { text: "英文", value: "en" },
        { text: "俄文", value: "ru" },
        { text: "粤语", value: "yue" },
        { text: "文言文", value: "wyw" },
        { text: "日语", value: "jp" },
        { text: "泰语", value: "th" },
        { text: "阿拉伯语", value: "ara" },
        { text: "葡萄牙语", value: "pt" },
        { text: "德语", value: "de" },
        { text: "意大利语", value: "it" },
        { text: "希腊语", value: "el" },
        { text: "荷兰语", value: "nl" },
        { text: "波兰语", value: "pl" },
        { text: "保加利亚语", value: "bul" },
        { text: "爱沙尼亚语", value: "est" },
        { text: "丹麦语", value: "dan" },
        { text: "芬兰语", value: "fin" },
        { text: "捷克语", value: "cs" },
        { text: "罗马尼亚语", value: "rom" },
        { text: "瑞典语", value: "swe" },
        { text: "斯洛文尼亚语", value: "slo" },
        { text: "匈牙利语", value: "hu" },
        { text: "繁体中文", value: "cht" },
        { text: "越南语", value: "vie" },
      ],
      toList: [
        { text: "英文", value: "en" },
        { text: "中文", value: "zh" },
        { text: "俄文", value: "ru" },
        { text: "粤语", value: "yue" },
        { text: "文言文", value: "wyw" },
        { text: "日语", value: "jp" },
        { text: "泰语", value: "th" },
        { text: "阿拉伯语", value: "ara" },
        { text: "葡萄牙语", value: "pt" },
        { text: "德语", value: "de" },
        { text: "意大利语", value: "it" },
        { text: "希腊语", value: "el" },
        { text: "荷兰语", value: "nl" },
        { text: "波兰语", value: "pl" },
        { text: "保加利亚语", value: "bul" },
        { text: "爱沙尼亚语", value: "est" },
        { text: "丹麦语", value: "dan" },
        { text: "芬兰语", value: "fin" },
        { text: "捷克语", value: "cs" },
        { text: "罗马尼亚语", value: "rom" },
        { text: "瑞典语", value: "swe" },
        { text: "斯洛文尼亚语", value: "slo" },
        { text: "匈牙利语", value: "hu" },
        { text: "繁体中文", value: "cht" },
        { text: "越南语", value: "vie" },
      ],

      textual: "",
      translated: "",
    };
  },
  methods: {
    translate() {
      Axios({
        method: "post",
        url: "http://api.byboy.cn/translate",
        data: {
          q: this.textual,
          from: this.from,
          to: this.to,
        },
      })
        .then((res) => {
          this.translated = res.data.trans_result[0].dst;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {},
};
</script>

<style lang="less" scoped>
#the-translation {
  width: 100%;
  height: 100%;
}
.van-icon-exchange {
  display: flex;
  flex: 0.5;
}
.van-field {
  width: 100%;
  margin-top: 5px;
}
.translate-btn {
  position: fixed;
  bottom: 0;
  margin-top: 100px;
}
.translated {
  margin-bottom: 10px;
  p {
    text-align: center;
    margin: 15px 0 10px;
  }
}
</style>