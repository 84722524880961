<template>
  <div id="app">
    <TheTranslation />
  </div>
</template>

<script>
import TheTranslation from "./components/TheTranslation";

export default {
  name: "App",
  components: {
    TheTranslation,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2c3e50;
  width: 100%;
  background-color: #f4f4f4;
  margin-bottom: 44px;
}
</style>
