import Vue from 'vue';
import App from './App.vue';
import '@/assets/css/global.css';
import { DropdownMenu, DropdownItem, Icon, Field, Button } from 'vant';
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Icon);
Vue.use(Field);
Vue.use(Button);
Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app');
